import React, { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../Firebase/init";
const EventElement = (props) => {

    const [AvdelingName, setAvdelingName] = useState()
    const [imgURL, setimgURL] = useState()

    const openInNewTab = url => {
        //logEvent(analytics, "Open_link_avdeling_" + props.event.location)
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function find_avdeling(avdelinger, location) {
        avdelinger.map(value => {
            if (value.id === location) {
                setAvdelingName(value.longname)
                setimgURL(value.imgURL)

            }
            return null
        })

    }
    useEffect(() => {
        find_avdeling(props.avdelinger, props.event.location)
    }, [props.avdelinger, props.event.location])


    function logEventGA() {
        console.log(props.event.location)
        logEvent(analytics, "" + props.event.location)
    }
    return (
        <div style={{ flex: "1 1 33.333333%" }} onClick={logEventGA} >
            <img className="img_BK" alt={AvdelingName} style={{ cursor: "pointer", height: "9em" }} onClick={() => openInNewTab(props.event.link)} src={imgURL}></img>
            <h3 style={{ cursor: "pointer" }} onClick={() => openInNewTab(props.event.link)}>{AvdelingName}</h3>

        </div >
    )
}
export default EventElement;