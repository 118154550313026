import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCQGLnD6aMvfjvp-eaipUX7PhbVrOHCAkU",
    authDomain: "bakekompisfrokost.firebaseapp.com",
    projectId: "bakekompisfrokost",
    storageBucket: "bakekompisfrokost.appspot.com",
    messagingSenderId: "290718807197",
    appId: "1:290718807197:web:c39a7dceacc2cba4cb9074",
    measurementId: "G-KLBTQ141S5"



}

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);


export default firebaseConfig