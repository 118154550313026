import React, { useState, useEffect, useRef } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../Firebase/init";
import EventElement from '../Elements/EventElement'
import Logo from '../Data/LogoBG.png'
import '../../App.css';

const EventList = () => {
    const [Avdelinger, setAvdelinger] = useState([])
    const [events, setevents] = useState([])
    const renderAfterCalled = useRef(false);

    function get_avdelinger() {
        logEvent(analytics, "API_avdeling")
        fetch('https://gb-avdeling.azurewebsites.net/avdeling')
            .then((response) => response.json())
            .then((data) => setAvdelinger(data));
    }
    function get_event() {
        logEvent(analytics, "API_data")
        fetch('https://gb-faktura.azurewebsites.net/bakekompisfrokost/data')
            .then((response) => response.json())
            .then((data) => setevents(data));
    }
    useEffect(() => {

        if (!renderAfterCalled.current) {
            get_avdelinger()
            get_event()
        }
        renderAfterCalled.current = true;
    }, [])
    return (
        <div style={{ display: "grid" }}>
            <img alt="Godt Brød logo" id="logo" style={{ margin: "auto", padding: "2em" }} height="120em" src={Logo}></img>

            <div className="eventListe"  >


                {events.map(value => <EventElement key={value.location} event={value} avdelinger={Avdelinger} />)}
            </div>

        </div >
    )
}
export default EventList;