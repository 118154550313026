import EventList from '../Elements/EventList'
import React, { useEffect } from "react";

const Home = () => {

    return (
        <div >
            <EventList />
        </div>
    );
};

export default Home;