import React, { useEffect } from "react";

import './App.css';
import Home from './components/Pages/Home';

function App() {
  useEffect(() => {
    document.title = 'Godt Brød - Bakekompisfrokost';
  });
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
